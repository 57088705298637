$('[data-toggle="menu"]').click(function(event) {
	var bgTopo = document.createElement('div');
	var topo = document.querySelector('.topo');

	$(bgTopo).addClass('bg-topo');

	$(bgTopo).click(function(event) {
		$(topo).removeClass('open');
		$('body,html').removeClass('menu-open');

		$(this).fadeOut(600, function() {
			$(this).remove();
		});
	});

	topo.appendChild(bgTopo);

	$(bgTopo).fadeIn(600);
	$(topo).addClass('open');
	$('body,html').addClass('menu-open');
});